.video {
  border-bottom: 1px solid rgba(104, 104, 104, 0.2);
  padding: 66px 0 79px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 370px));
  grid-auto-flow: auto;
  grid-template-rows: auto;
  row-gap: 32px;
  column-gap: 30px;
}

  @media (max-width: 1200px) {.video {
    grid-template-columns: repeat(3, minmax(190px, 370px))
}
  }

  @media (max-width: 926px) {.video {
    /* grid-template-columns: repeat(3, minmax(190px, 370px)); */
}
  }

  .video__title {
    grid-column: 1 / 3;
  }

  .video__more {
    align-self: end;
    justify-self: end;
    margin-bottom: 11px;
  }

  @media (max-width: 1200px) {

  .video__more {
      grid-column: 3 / 3
  }
    }

  .video .card.card--m {
    grid-column: span 1;
  }

  @media (max-width: 926px) {

  .video .card.card--m {
      grid-column: 1 / 4
  }
    }

@media (max-width: 926px) {
  .video {
    grid-template-columns: repeat(1, minmax(130px, auto));
  }
}
